import React, { useEffect } from 'react';

import OnlyAnonymous from '@components/Auth/OnlyAnonymous';
import Signup from '@components/Pages/Signup';
import { MaintenanceMode } from '@presenters/web/pages/MaintenanceMode';

import { useMaintenanceModeCheck } from '@repositories/maintenance-mode/get-maintenance-mode';

import { log } from '@hooks/logger';

import { LogLevel } from '@typings/graphql';

const SignupPage: React.FC = () => {
  const checkMaintenanceMode = useMaintenanceModeCheck();

  useEffect(() => {
    log({
      level: LogLevel.Debug,
      message: `Drupal: checkMaintenanceMode, request to ${process.env.GATSBY_BACKEND_APP_BASE_URL}/en/restapi/maintenance-mode`,
      other: {
        requestData: {
          manual: true,
          withCredentials: true,
        },
        response: checkMaintenanceMode,
      },
    });
  }, [checkMaintenanceMode?.data?.maintenanace]);

  if (checkMaintenanceMode?.data?.maintenanace === 1) {
    return <MaintenanceMode />;
  }

  return (
    <OnlyAnonymous redirectTo="/account/settings">
      <Signup />
    </OnlyAnonymous>
  );
};

export default SignupPage;
