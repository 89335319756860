import React, { useRef } from 'react';

import { Form, Formik, FormikHelpers } from 'formik';
import { Maybe } from 'graphql/jsutils/Maybe';
import { TFunction } from 'i18next';
import { isEmpty } from 'lodash';
import ReCAPTCHA from 'react-google-recaptcha';
import * as yup from 'yup';

import { Button } from '@components/Button';
import Divider from '@components/Divider';
import RadioField from '@components/Formik/RadioField';
import YearOfBirthSelect from '@components/Formik/Select/YearOfBirthSelect';
import TextField from '@components/Formik/TextField';
import {
  firstNameSchema,
  lastNameSchema,
  notRotaryEmailRequiredSchema,
} from '@components/Formik/validation/fieldDefinitions';
import {
  isRequired,
  schemaField,
} from '@components/Formik/validation/schemaDefinitions';

import { useRecaptcha } from '@use-cases/account/hook';

import { FEATURE_TEST_EMAIL, isEnabled } from '@utils/features';
import { getLanguageFromURL } from '@utils/query-params';

import { useTranslation } from '@external/react-i18next';

const validationSchema = (t: TFunction) =>
  yup.object().shape({
    firstName: firstNameSchema(t),
    lastName: lastNameSchema(t),
    email: notRotaryEmailRequiredSchema(t),
    youthFlag: schemaField(t, yup.string(), isRequired),
    yearOfBirth: schemaField(
      t,
      yup.string().when('youthFlag', {
        is: youthFlag => youthFlag === 'no',
        then: yup.string().required(),
      })
    ),
  });

export interface FormValues {
  email: string;
  firstName: string;
  lastName: string;
  youthFlag: string;
  yearOfBirth: string;
}

interface Props {
  handleSubmit: (values: FormValues, token: Maybe<string>) => void;
}
const SignupForm: React.FC<Props> = ({ handleSubmit }) => {
  const { t } = useTranslation();

  const recaptchaRef = useRef(null);
  const {
    onRecaptchaChange,
    onRecaptchaExpired,
    resetCaptcha,
    token,
  } = useRecaptcha(recaptchaRef);

  const isTestEmailFeature = isEnabled(FEATURE_TEST_EMAIL);

  const handleFormSubmit = (
    values: FormValues,
    helpers: FormikHelpers<FormValues>
  ) => {
    handleSubmit(values, token);
    helpers.setSubmitting(false);
    resetCaptcha();
  };

  const options = [
    {
      value: 'yes',
      label: t('signup.form.radio-label.yes', 'Yes'),
    },
    {
      value: 'no',
      label: t('signup.form.radio-label.no', 'No'),
    },
  ];

  return (
    <Formik
      onSubmit={handleFormSubmit}
      initialValues={{
        email: '',
        firstName: '',
        lastName: '',
        youthFlag: '',
        yearOfBirth: '',
      }}
      validationSchema={validationSchema(t)}
    >
      {({ isSubmitting, isValid, touched, values }) => {
        const isSubmitButtonDisabled =
          isSubmitting ||
          (!token && !isTestEmailFeature) ||
          !isValid ||
          isEmpty(touched);

        return (
          <Form>
            <TextField
              name="firstName"
              label={t('signup.form.firstname-label', 'First Name')}
              required
            />
            <TextField
              name="lastName"
              label={t('signup.form.lastname-label', 'Last Name')}
              required
            />
            <TextField
              name="email"
              label={t('signup.form.email-label', 'Email')}
              required
            />
            <RadioField
              name="youthFlag"
              label={t('signup.form.radio-label', 'Are you 18 years or older?')}
              required
              options={options}
            />
            {values.youthFlag === 'no' && (
              <>
                <p className="mt-10">
                  {t('signup.form.year-of-birth-description', 'Lorem ipsum')}
                </p>
                <YearOfBirthSelect
                  name="yearOfBirth"
                  label={t('signup.form.year-of-birth-label', 'Year of Birth')}
                  yearsRange={17}
                  acceptedAge={0}
                  required
                />
              </>
            )}
            <Divider />
            <p className="text-sm font-extralight">
              {t(
                'signpup.form.disclaimer',
                'By creating an account, you agree to the <a>Terms of Service</a> and acknowledge our <a>Privacy Policy</a>.'
              )}
            </p>
            {!isTestEmailFeature && (
              <ReCAPTCHA
                hl={getLanguageFromURL()}
                ref={recaptchaRef}
                sitekey={process.env.GATSBY_RECAPTCHA_SITE_KEY || ''}
                onChange={onRecaptchaChange}
                onExpired={onRecaptchaExpired}
              />
            )}
            <Button disabled={isSubmitButtonDisabled}>
              {t('signup.form.button-label', 'Continue')}
            </Button>
          </Form>
        );
      }}
    </Formik>
  );
};

export default SignupForm;
