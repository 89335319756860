import React, { useState } from 'react';

import { OneColumn } from '@components/Layouts/OneColumn';
import Title from '@components/Title';
import { useTranslation } from '@external/react-i18next';
import { MaintenanceModeContext } from '@use-cases/maintenance-mode';

export const MaintenanceMode = () => {
  const { t } = useTranslation();

  return (
    <OneColumn>
      <div className="max-w-2xl mb-20">
        <Title>{t('maintenance-mode.title', `Maintenance Mode`)}</Title>
        <div className="mt-10 mb-6 text-l">
          <p>
            {t(
              'maintenance-mode.text',
              `The application is currently in maintenance mode. Please come back later.`
            )}
          </p>
        </div>
      </div>
    </OneColumn>
  );
};

const MaintenanceModePage = () => {
  const { t } = useTranslation();
  return (
    <div className="min-h-full flex flex-col justify-between">
      <div>
        <header>
          <div className="h-32 bg-dark-blue-600 flex items-center">
            <OneColumn>
              <img
                src="/logo.png"
                alt={t('global.siteTitle', 'Rotary International')}
                style={{ maxWidth: '232px' }}
              />
            </OneColumn>
          </div>
        </header>
        <MaintenanceMode />
      </div>
      <footer className="bg-dark-blue-600 h-32" />
    </div>
  );
};

export const MaintenanceModeProvider: React.FC = ({ children }) => {
  const [isActive, setIsActive] = useState(false);
  const activateMaintenanceMode = () => setIsActive(true);

  return (
    <MaintenanceModeContext.Provider value={{ activateMaintenanceMode }}>
      {isActive ? <MaintenanceModePage /> : children}
    </MaintenanceModeContext.Provider>
  );
};
