import { gql, useMutation } from '@apollo/client';

import {
  CreateAccountMutation,
  CreateAccountMutationVariables,
} from '@typings/operations';

export const mutation = gql`
  mutation createAccount(
    $firstName: String!
    $lastName: String!
    $email: String!
    $langcode: String!
    $youthFlag: Boolean!
    $yearOfBirth: Int
    $recaptchaToken: String
  ) {
    createAccount(
      firstName: $firstName
      lastName: $lastName
      email: $email
      langcode: $langcode
      youthFlag: $youthFlag
      yearOfBirth: $yearOfBirth
      recaptchaToken: $recaptchaToken
    )
  }
`;

export const useCreateAccountMutation = () =>
  useMutation<CreateAccountMutation, CreateAccountMutationVariables>(mutation);
